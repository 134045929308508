.clients {
    padding: 100px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;

    &__title {
        text-align: center;
    }

    &__logos {
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        img {
            margin: 20px;
            height: 70px;
            object-fit: contain;
        }
    }

    &__numbers {
        display: flex;
        width: 60%;
        justify-content: space-between;
        padding: 200px 0;
    }

    &__number {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        h2 {
            font-size: 7em;
        }

        h3 {
            font-size: 2em;
        }
    }
}

$breakpoints: (
    mobile: 40em,
    tablet: 52em,
    desktop: 72em,
);

@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: calc(#{$width} - 1px);
        }
        @media all and (#{$type}-width: $width) {
            @content;
        }
    }
}

@mixin landscape() {
    @media all and (orientation: landscape) {
        @content;
    }
}

@mixin portrait() {
    @media all and (orientation: portrait) {
        @content;
    }
}

@import url('https://use.typekit.net/pio0ajq.css');

:root {
    font-size: 14px;
    
    @include mq(tablet) {
        font-size: 16px;
    }
}

@font-face {
    font-family: 'GeneralSans-Bold';
    src: url('../fonts/GeneralSans-Bold.woff2') format('woff2'),
        url('../fonts/GeneralSans-Bold.woff') format('woff'),
        url('../fonts/GeneralSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Outfit-Light';
    src: url('../fonts/Outfit-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Outfit-Medium';
    src: url('../fonts/Outfit-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

h1, h2, h3, h4, h5, p, span {
    font-kerning: none;
}

h1, .typo--h1 {
    font-family: 'GeneralSans-Bold', sans-serif;
    font-size: 3.6rem;
    line-height: 1.1em;
}

h2, .typo--h2 {
    font-family: 'Outfit-Medium', sans-serif;
    font-size: 3rem;
}

h3, .typo--h3 {
    font-family: 'ivypresto-display', serif;
    font-weight: 600;
    font-size: 2.6rem;
    font-style: italic;
}

p, .typo--body {
    font-family: 'Outfit-Light', sans-serif;
    font-size: 1.3rem;
    line-height: 1.5em;
}

.oh {
	overflow: hidden;
    display: inline-block;

    &__inner {
        display: inline-block;
        transform-origin: 0% 50%;
        will-change: transform;

    }
}

@import 'mouse-follower/src/scss';

@import 'reset';
@import 'rwd';
@import 'typo';
@import 'button';

@import 'layout';
@import 'hero';
@import 'expertise';
@import 'clients';
@import 'cta';
@import 'menu';
@import 'header';
@import 'footer';

:root {
    --mouse-x: 50%;
    --mouse-y: 50%;
}

.mf-cursor {
    backdrop-filter: blur(10px);

    &::before {
        width: 80px;
        height: 80px;
        top: -40px;
        left: -40px;
        background: rgba(58, 56, 112, 1);
    }

    &-text {
        font-family: 'Outfit-Light', sans-serif;
        font-size: 1rem;
    }
}

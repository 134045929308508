.menu {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    &__items {
    }

    &__item {

    }
}

@import "https://use.typekit.net/pio0ajq.css";
.mf-cursor {
  z-index: 250;
  direction: ltr;
  contain: layout style size;
  pointer-events: none;
  transition: opacity .3s, color .4s;
  position: fixed;
  top: 0;
  left: 0;
}

.mf-cursor:before {
  content: "";
  width: 48px;
  height: 48px;
  background: currentColor;
  border-radius: 50%;
  transition: transform .25s ease-in-out, opacity .1s;
  display: block;
  position: absolute;
  top: -24px;
  left: -24px;
  transform: scale(.2);
}

.mf-cursor.-inverse {
  color: #fff;
}

@supports (mix-blend-mode: exclusion) {
  .mf-cursor.-exclusion {
    mix-blend-mode: exclusion;
  }

  .mf-cursor.-exclusion:before {
    background: #fff;
  }
}

.mf-cursor.-pointer:before {
  transform: scale(.15);
}

.mf-cursor.-text:before {
  opacity: .85;
  transform: scale(1.7);
}

.mf-cursor.-text.-active:before {
  transition-duration: .2s;
  transform: scale(1.6);
}

.mf-cursor.-icon:before {
  transform: scale(1.5);
}

.mf-cursor.-icon.-active:before {
  transform: scale(1.4);
}

.mf-cursor.-hidden:before {
  transform: scale(0);
}

.mf-cursor-text {
  width: 36px;
  height: 36px;
  opacity: 0;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  transition: opacity .4s, transform .3s;
  display: flex;
  position: absolute;
  top: -18px;
  left: -18px;
  transform: scale(0)rotate(10deg);
}

.mf-cursor.-text .mf-cursor-text, .mf-cursor.-icon .mf-cursor-text {
  opacity: 1;
  transform: scale(1);
}

.mf-cursor-media {
  width: 400px;
  height: 400px;
  margin: -200px 0 0 -200px;
  position: absolute;
}

.mf-cursor-media img, .mf-cursor-media video {
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@supports (object-fit: cover) {
  .mf-cursor-media img, .mf-cursor-media video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: static;
    transform: translateZ(0);
  }
}

.mf-cursor-media-box {
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 50%;
  padding: 1px;
  transition: transform .35s, opacity .2s .2s;
  position: relative;
  overflow: hidden;
  transform: scale(0)translateZ(0);
}

.mf-cursor.-media .mf-cursor-media-box {
  opacity: 1;
  transition-duration: .4s, .4s;
  transition-delay: 0s, 0s;
  transform: scale(.696);
}

html, body {
  min-width: 320px;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0;
  outline: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

body, dl, menu, ol, ul, fieldset, figure, form, button, input, select, textarea, dt, dd, p {
  margin: 0;
  padding: 0;
}

ul, ol {
  list-style: none;
}

fieldset {
  border: 0;
}

textarea {
  vertical-align: top;
  resize: vertical;
  overflow: auto;
}

audio, canvas, video {
  width: 100%;
  vertical-align: middle;
  display: inline-block;
}

a, a:active, a:hover, a:focus {
  -webkit-tap-highlight-color: transparent;
  outline: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 5px 0;
  font-weight: normal;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-family: inherit;
  font-weight: normal;
}

blockquote {
  margin: 1em 40px;
}

dfn {
  font-style: italic;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 1em 0;
}

code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, select, textarea {
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: text;
  user-select: text;
  border-radius: 0;
  font-family: inherit;
  font-size: 100%;
}

button:focus, input:focus, select:focus, textarea:focus {
  outline: 0;
}

button, input {
  border: 0;
  line-height: normal;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: none;
  background-color: #0000;
  border: 0;
  outline: 0;
}

input[type="date"] {
  background-color: #0000;
}

input::-moz-focus-inner, button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button[disabled], html input[disabled] {
  cursor: not-allowed;
}

input[type="checkbox"], input[type="radio"] {
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: inherit;
  outline: 0;
  text-decoration: none;
}

:root {
  font-size: 14px;
}

@media (min-width: 52em) {
  :root {
    font-size: 16px;
  }
}

@font-face {
  font-family: GeneralSans-Bold;
  src: url("GeneralSans-Bold.bc1eb702.woff2") format("woff2"), url("GeneralSans-Bold.6afbfc5d.woff") format("woff"), url("GeneralSans-Bold.75d1d4cc.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Outfit-Light;
  src: url("Outfit-Light.7cb8616e.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Outfit-Medium;
  src: url("Outfit-Medium.194ef817.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

h1, h2, h3, h4, h5, p, span {
  font-kerning: none;
}

h1, .typo--h1 {
  font-family: GeneralSans-Bold, sans-serif;
  font-size: 3.6rem;
  line-height: 1.1em;
}

h2, .typo--h2 {
  font-family: Outfit-Medium, sans-serif;
  font-size: 3rem;
}

h3, .typo--h3 {
  font-family: ivypresto-display, serif;
  font-size: 2.6rem;
  font-style: italic;
  font-weight: 600;
}

p, .typo--body {
  font-family: Outfit-Light, sans-serif;
  font-size: 1.3rem;
  line-height: 1.5em;
}

.oh {
  display: inline-block;
  overflow: hidden;
}

.oh__inner {
  transform-origin: 0%;
  will-change: transform;
  display: inline-block;
}

.button {
  text-align: center;
  border: 2px solid #000;
  border-radius: 30px;
  padding: 10px 30px;
  font-family: Outfit-Light, sans-serif;
  font-size: 1.3rem;
  display: block;
}

.button--light {
  color: #fff;
  border-color: #fff;
}

.wrapper {
  max-width: 1440px;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  display: flex;
}

@media (min-width: 52em) {
  .wrapper {
    padding-top: 0;
  }
}

.logo {
  transition: all .5s ease-in-out;
  position: relative;
}

@media (min-width: 52em) {
  .logo {
    transform: translateX(0);
  }
}

.logo__image {
  width: 200px;
  transition: all .5s ease-in-out;
}

.logo__image--sign {
  height: 60px;
  transition-duration: 1s;
  display: none;
  position: absolute;
  bottom: -10px;
  right: -10px;
  transform: scale(0);
}

@media (min-width: 52em) {
  .logo__image--sign {
    display: block;
  }
}

.loader {
  will-change: transform;
  transform-origin: 50% 0;
  z-index: 200;
  background-color: #fff;
  transition: transform 1s cubic-bezier(.175, .885, .32, 1.275) .2s;
  position: fixed;
  inset: 0;
  transform: scale3d(1, 0, 0);
}

.layout__video {
  z-index: -1;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: .3;
  position: fixed;
}

@media (min-width: 52em) {
  .layout__video {
    opacity: .4;
    mask-type: alpha;
    -webkit-mask: radial-gradient(circle at var(--mouse-x) var(--mouse-y), black 150px, #0000 500px);
    mask: radial-gradient(circle at var(--mouse-x) var(--mouse-y), black 150px, #0000 500px);
    position: absolute;
  }

  .layout--scrolled .logo {
    transform: translateX(-150px);
  }

  .layout--scrolled .logo__image {
    opacity: 0;
  }

  .layout--scrolled .logo__image--sign {
    opacity: 1;
    transform: scale(1);
  }
}

.layout--loading {
  transform: scale3d(1, 1, 0);
}

.layout--menu-open .menu {
  display: flex;
}

.hero {
  margin-top: 80px;
}

.hero .wrapper {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media (min-width: 52em) {
  .hero {
    margin-top: 0;
  }
}

.hero__typo {
  z-index: 1;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: absolute;
}

.hero__typo p {
  max-width: 80%;
  padding-top: 2em;
}

@media (min-width: 52em) {
  .hero__typo p {
    max-width: 60%;
  }
}

.hero__scroll {
  flex-direction: column;
  align-items: center;
  display: none;
  position: absolute;
  bottom: 50px;
  left: 50%;
}

.hero__scroll-icon {
  width: 20px;
}

.hero__scroll span {
  text-align: center;
  padding: 20px 0;
  font-family: Outfit-Medium;
  font-size: 18px;
}

@media (min-width: 52em) {
  .hero__scroll {
    display: flex;
  }
}

.expertise {
  background-color: #fff;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (min-width: 52em) {
  .expertise {
    background-image: linear-gradient(#f3f7f9 0%, #f3f7f900 50%);
    padding-top: 200px;
  }
}

.expertise__service {
  flex-direction: column;
  display: flex;
}

@media (min-width: 52em) {
  .expertise__service {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 40px 0;
  }

  .expertise__service--right {
    text-align: right;
    flex-direction: row-reverse;
  }
}

.expertise__service-name {
  padding-bottom: .5em;
}

.expertise__fig {
  aspect-ratio: 1 / 1;
  position: relative;
  overflow: hidden;
}

.expertise__fig:hover .expertise__pic {
  transform: scale(1.05);
}

.expertise__fig:hover .expertise__image {
  filter: brightness(1.04);
}

.expertise__pic {
  width: 100%;
  height: auto;
  will-change: transform;
  transition: transform 2s cubic-bezier(.16, 1, .3, 1);
  position: absolute;
  top: 0;
  left: 0;
}

.expertise__image {
  width: 100%;
  height: 100%;
  transition: filter .5s;
  transform: translateY(-20%);
}

.expertise__title {
  text-align: center;
  padding: 100px 0;
}

@media (min-width: 52em) {
  .expertise__title {
    padding: 0 0 50px;
  }
}

.expertise__description {
  background-color: #fff;
  flex-direction: column;
  padding: 100px 50px;
}

@media (min-width: 52em) {
  .expertise__description {
    background: none;
  }

  .expertise__fig {
    width: 40%;
    border-radius: 10%;
    padding-top: 40%;
  }

  .expertise__description {
    width: 50%;
  }
}

.clients {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  padding: 100px 50px;
  display: flex;
}

.clients__title {
  text-align: center;
}

.clients__logos {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  display: flex;
}

.clients__logos img {
  height: 70px;
  object-fit: contain;
  margin: 20px;
}

.clients__numbers {
  width: 60%;
  justify-content: space-between;
  padding: 200px 0;
  display: flex;
}

.clients__number {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.clients__number h2 {
  font-size: 7em;
}

.clients__number h3 {
  font-size: 2em;
}

.cta {
  height: 70vh;
  background-color: #17113c;
  position: relative;
}

.cta__typo, .cta__background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cta__background {
  object-fit: cover;
  filter: opacity(.2) contrast(1.5);
  mix-blend-mode: luminosity;
}

.cta__typo {
  color: #fff;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

@media (min-width: 52em) {
  .cta__typo {
    max-width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.cta__go {
  margin: 50px 0;
}

.cta h3 {
  padding-bottom: 2rem;
  font-size: 4.5rem;
  line-height: .9;
}

@media (min-width: 52em) {
  .cta h3 {
    padding-bottom: 1rem;
    font-size: 5rem;
    line-height: normal;
    transform: translateX(-70px);
  }

  .cta h3 span {
    padding-left: 200px;
    display: inline-block;
    transform: translateY(-40px);
  }
}

.cta h1 {
  padding: 0 50px;
  font-family: Outfit-Light, sans-serif;
  font-size: 2rem;
}

.menu {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
}

.header {
  width: 100%;
  z-index: 100;
  max-width: 2500px;
  background: #fff;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  position: fixed;
  top: 0;
  box-shadow: 0 10px 25px #0000001a;
}

@media (min-width: 52em) {
  .header {
    box-shadow: none;
    background: none;
    padding: 30px;
  }
}

.header__menu {
  align-items: center;
  display: flex;
}

.header__menu svg {
  width: 32px;
}

.header__menu-label {
  padding-right: 20px;
  font-family: Outfit-Medium;
  font-size: 26px;
  display: none;
}

@media (min-width: 52em) {
  .header__menu-label {
    display: inline-block;
  }
}

.footer {
  padding: 150px 30px;
}

@media (min-width: 52em) {
  .footer {
    padding: 150px 50px;
  }
}

.footer .logo {
  margin: 100px 0 50px;
  display: none;
}

.footer__email {
  font-size: 3.5rem;
  text-decoration: underline;
}

.footer__phone {
  padding-bottom: 40px;
  font-size: 2.4rem;
}

.footer__address {
  color: #666;
  font-size: 1.2rem;
}

.footer__address-title {
  font-size: 1.4em;
}

.footer__copyright {
  color: #999;
  padding-top: 100px;
  font-size: .8rem;
}

:root {
  --mouse-x: 50%;
  --mouse-y: 50%;
}

.mf-cursor {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.mf-cursor:before {
  width: 80px;
  height: 80px;
  background: #3a3870;
  top: -40px;
  left: -40px;
}

.mf-cursor-text {
  font-family: Outfit-Light, sans-serif;
  font-size: 1rem;
}

/*# sourceMappingURL=index.d73aed9e.css.map */

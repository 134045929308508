.header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    max-width: 2500px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 10px 25px rgba(0,0,0,0.1);

    @include mq(tablet) {
        background: transparent;
        box-shadow: none;
        padding: 30px;
    }

    &__menu {
        display: flex;
        align-items: center;

        svg {
            width: 32px;
        }

        &-label {
            padding-right: 20px;
            font-family: 'Outfit-Medium';
            font-size: 26px;
            display: none;

            @include mq(tablet) {
                display: inline-block;
            }
        }
    }
}

.button {
    border: 2px solid #000;
    padding: 10px 30px;
    text-align: center;
    display: block;
    border-radius: 30px;
    font-family: 'Outfit-Light', sans-serif;
    font-size: 1.3rem;

    &--light {
        border-color: #fff;
        color: #fff;
    }
}

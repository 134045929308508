.cta {
    position: relative;
    height: 70vh;
    background-color: #17113c;

    &__typo,
    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__background {
        object-fit: cover;
        filter: opacity(0.2) contrast(1.5);
        mix-blend-mode: luminosity;
    }

    &__typo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-align: center;
        margin: 0 auto;
        
        @include mq(tablet) {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: 400px;
        }
    }

    &__go {
        margin: 50px 0;
    }

    h3 {
        font-size: 4.5rem;
        line-height: 0.9;
        padding-bottom: 2rem;
        
        @include mq(tablet) {
            transform: translateX(-70px);
            font-size: 5rem;
            line-height: normal;
            padding-bottom: 1rem;
                
            span {
                display: inline-block;
                padding-left: 200px;
                transform: translateY(-40px);
            }
        }

    }

    h1 {
        font-size: 2rem;
        font-family: 'Outfit-Light', sans-serif;
        padding: 0 50px;
    }
}

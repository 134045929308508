.expertise {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    
    @include mq(tablet) {
        padding-top: 200px;
        background-image: linear-gradient(
            to bottom,
            rgba(243, 247, 249, 1) 0%,
            rgba(243, 247, 249, 0) 50%
        );
    }

    &__service {
        display: flex;
        flex-direction: column;

        @include mq(tablet) {
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            padding: 40px 0;

            &--right {
                flex-direction: row-reverse;
                text-align: right;
            }
        }

        &-name {
            padding-bottom: 0.5em;
        }
    }

    &__fig {
        position: relative;
        aspect-ratio: 1 / 1;
        overflow: hidden;

        &:hover {
            .expertise__pic {
                transform: scale(1.05);
            }

            .expertise__image {
                filter: brightness(1.04);
            }
        }
    }

    &__pic {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        transition: transform 2s cubic-bezier(0.16, 1, 0.3, 1);
        will-change: transform;
    }

    &__image {
        transform: translateY(-20%);
        transition: filter 0.5s ease;
        width: 100%;
        height: 100%;
    }

    &__title {
        text-align: center;
        padding: 100px 0;

        @include mq(tablet) {
            padding: 0;
            padding-bottom: 50px;
        }
    }

    &__description {
        flex-direction: column;
        padding: 100px 50px;
        background-color: white;

        @include mq(tablet) {
            background: transparent;
        }
    }

    @include mq(tablet) {
        &__fig {
            width: 40%;
            padding-top: 40%;
            border-radius: 10%;
        }

        &__description {
            width: 50%;
        }
    }
}

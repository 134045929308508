.wrapper {
    margin: 0 auto;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include mq(tablet) {
        padding-top: 0;
    }
}

.logo {
    position: relative;
    transition: all 0.5s ease-in-out;

    @include mq(tablet) {
        transform: translateX(0);
    }

    &__image {
        width: 200px;
        transition: all 0.5s ease-in-out;

        &--sign {
            display: none;
            position: absolute;
            right: -10px;
            bottom: -10px;
            height: 60px;
            transform: scale(0);
            transition-duration: 1s;

            @include mq(tablet) {
                display: block;
            }
        }
    }
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    will-change: transform;
    transform-origin: 0 50%;
    transition: transform 1s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform-origin: 50% 0;
    transform: scale3d(1, 0, 0);
    z-index: 200;
}

.layout {
    &__video {
        position: fixed;
        z-index: -1;
        width: 100%;
        height: 100vh;
        object-fit: cover;
        opacity: 0.3;

        @include mq(tablet) {
            opacity: 0.4;
            mask-type: alpha;
            position: absolute;
            mask: radial-gradient(
                circle at var(--mouse-x) var(--mouse-y),
                black 150px,
                rgba(0, 0, 0, 0) 500px
            );
        }
    }

    &--scrolled {
        .logo {
            @include mq(tablet) {
                transform: translateX(-150px);

                &__image {
                    opacity: 0;

                    &--sign {
                        transform: scale(1);
                        opacity: 1;
                    }
                }
            }
        }
    }

    &--loading {
        transform: scale3d(1, 1, 0);
    }

    &--menu-open {
        .menu {
            display: flex;
        }
    }
}

.footer {
    padding: 150px 30px;

    @include mq(tablet) {
        padding: 150px 50px;
    }

    .logo {
        margin: 100px 0 50px 0;
        display: none;
    }

    &__email {
        font-size: 3.5rem;
        text-decoration: underline;
    }
    &__phone {
        font-size: 2.4rem;
        padding-bottom: 40px;
    }

    &__address {
        font-size: 1.2rem;
        color: #666;

        &-title {
            font-size: 1.4em;
        }
    }

    &__copyright {
        padding-top: 100px;
        font-size: 0.8rem;
        color: #999;
    }
}

.hero {
    margin-top: 80px;

    .wrapper {
        position: relative;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @include mq(tablet) {
        margin-top: 0;
    }

    &__typo {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
        text-align: center;

        p {
            padding-top: 2em;
            max-width: 80%;

            @include mq(tablet) {
                max-width: 60%;
            }
        }
    }

    &__scroll {
        position: absolute;
        flex-direction: column;
        align-items: center;
        bottom: 50px;
        left: 50%;
        display: none;

        &-icon {
            width: 20px;
        }

        span {
            padding-right: 20px;
            font-family: 'Outfit-Medium';
            font-size: 18px;
            text-align: center;
            padding: 20px 0;
        }

        @include mq(tablet) {
            display: flex;
        }
    }
}
